@use 'src/src/app/scss/abstracts' as *;

.avatar {
  $block-name: &;

  @include reset-text-indents;

  position: relative;
  z-index: 0;

  &__inner {
    display: inline-flex;
    border: 5px solid $color-semantic-main;
    overflow: hidden;
    border-radius: 50%;
  }

  &__image {
    position: relative;
    width: 71px;
    height: 71px;
    margin: 0;
    padding: 0;
  }

  &--size-xs {
    #{$block-name} {
      &__image {
        width: 33px;
        height: 33px;
      }

      &__inner {
        border-width: 3px;
      }
    }
  }
}
