@use 'src/src/app/scss/abstracts' as *;

.viewer {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__avatar {
    margin-bottom: 10px;
  }

  &__email {
  }

  &__logout {
    margin-top: 30px;
  }

  &__dropdown-toggler {
  }

  &__dropdown-body {
    position: absolute;
    z-index: z('other');
    top: 100%;
    right: 0;
    min-width: 84px;
    max-width: 250px;
    height: 0;
    margin: 0;
    overflow: hidden;
    transform: translateY(10px);
    transition:
      opacity 0.15s ease,
      transform 0.15s ease;
    margin-top: 10px;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 4px 34px 0 rgb(118 69 255 / 50%);
    border-radius: $border-radius-s;

    @include mq($until: lg) {
      min-width: 58px;
    }
  }

  &__dropdown-body-inner {
    position: relative;
    padding: 20px;
    text-align: center;

    // max-height: 75vmin;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    font-size: 0;
    line-height: 0;
    border-radius: $border-radius-s;
    overflow: hidden;
    background-color: $color-semantic-main;
  }

  &__dropdown {
    display: inline-block;
    position: relative;
    z-index: $z-index-dropdown;

    &--is-open {
      display: inline-block;
      position: relative;

      #{$block-name} {
        &__dropdown-toggler {
        }

        &__dropdown-body {
          height: auto;
          visibility: visible;
          overflow: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
