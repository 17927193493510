@use 'src/src/app/scss/abstracts' as *;

.footer-playtest {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  color: rgba($color-semantic-third, 0.85);
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  @include mq($until: md) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__inner {
    margin-left: -12px;

    @include mq($until: md) {
      margin-left: 0;
    }
  }

  &__bottom {
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: rgba($color-semantic-third, 0.85);

    @include mq($until: md) {
      justify-content: center;
    }
  }

  &__year {
    padding: 12px;
    font-size: $font-size-xl;
    line-height: $font-line-l;

    @include mq($until: md) {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: $font-size-2xs;
      line-height: $font-line-l;
    }
  }

  &__logo-link {
    margin-top: -3px;
    color: rgba($color-semantic-third, 0.85);
    transition: color $transition-fast;

    @include mq($until: md) {
      margin-top: -1px;
    }

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }

    .icon {
      @include mq($until: md) {
        width: 58px;
        height: 20px;
      }
    }
  }

  &__all-rights {
    font-size: $font-size-xl;
    line-height: $font-line-l;
    padding: 12px;

    @include mq($until: md) {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: $font-size-2xs;
      line-height: $font-line-l;
    }
  }
}
